.contenedor-imagen {

 background-image: url(../assets/home3-slider.png);
 @media (max-width: 600px) {
  background-color: #19d8db;

 }
 background-position: bottom;
 background-size: cover;
 display: block;
 background-repeat: no-repeat;
  background-size: cover;
  height: 100vh !important;
  margin-top: 0;
  position: relative;
  width: 100%;

/*   background-color: #19d8db;
 */
/*   background-color:#8BE6E2;
   /* background-color: #5AC6C3; */
/*  background-color: #8bdedd; */
/* background-color: #94f5e9; */

 display: flex;
  justify-content: center;
  align-items: center;
/*   padding-top: 50px; */

}
.centrado {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 20px; /* Ajusta este valor según sea necesario */
}

.centrado > div {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  letter-spacing: .05em;
  line-height: 1.9em;
  text-transform: capitalize;
  margin-top: -100px; /* Ajusta este valor para subir el texto */
 
}

.centrado > div h2, .centrado > div p {
  margin: 0;
}

.centrado > div h2 {
  font-size: 24px; /* Ajusta según sea necesario */
  font-family: 'Poppins', sans-serif;
}

.centrado > div p {
  font-size: 18px; /* Ajusta según sea necesario */
  margin-top: 30px; /* Espacio entre el h2 y el párrafo */
}

@media (max-width: 600px) {
  .contenedor-imagen h1 {
    font-size: 30px; /* Tamaño de fuente más pequeño para dispositivos móviles */
 
 padding-top: 20px;
padding-left: 10px;
padding-right: 10px; }
  
  .contenedor-imagen h4 {
    font-size: 16px; /* Tamaño de fuente más pequeño para dispositivos móviles */
    padding-top: 30px;
    padding-left: 10px;
padding-right: 10px; 
  }
  .centrado > div {
   padding-top: 100px;
   margin-top: 30px;
padding-left: 9px;
padding-right: 9px;
letter-spacing: .03em;
  line-height: 2.0em;
  }

  .centrado > div p {
    font-size: 15px; /* Ajusta según sea necesario */
    margin-top: 30px; /* Espacio entre el h2 y el párrafo */
    letter-spacing: .00em;
    line-height: 1.8em;

  }
}

.texto-superpuesto {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10%; /* Ajusta este valor según tu diseño */
  z-index: 2;
  color: white;
}
.boton-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Espacio entre el texto y el botón */
}

.boton-container > button {
  /* Ajusta el tamaño del botón si es necesario */
}
.imagen-fondo {
  position: absolute;
  right: 0; /* Ajusta este valor para posicionar la imagen a la derecha */
  top: 0;
  width: 50%; /* Ajusta este valor según tu diseño */
  height: 100%; /* Ajusta este valor según tu diseño */
  object-fit: cover;
}

/* Media queries para dispositivos móviles */
@media (max-width: 600px) {
  .texto-superpuesto {
    position: relative;
    transform: translateY(0);
    left: 0;
    text-align: center;
  }

  .imagen-fondo {
    position: relative;
    width: 100%;
    height: auto;
  }
}
