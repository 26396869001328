.field {
    margin-bottom: 10px;
    align-items: center;
    align-content: center;
  }
  
  .field label {
    display: block;
    font-size: 12px;
    color: #777;
  }
  
  .field input {
    display: block;
    min-width: 250px;
    line-height: 1.5;
    font-size: 14px;
  }
  
  input[type="submit"] {
    display: block;
    padding: 6px 30px;
    font-size: 14px;
    background-color: #4460AA;
    color: #fff;
    border: none
  }
  .my-input {
    width: 100%;
    line-height: 1.5;
    font-size: 14px;
    margin-bottom: 10px;
  }
  